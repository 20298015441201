export const KC_CONF = {
    url: process.env.VARS.KEYCLOAK_AMS_AUTH_URL_SECURED,
    realm: 'credref',
    clientId: 'CR-CP',
}

function getKeycloakEnvConfigAndExecute(callback) {
    callback(KC_CONF)
}

function getUserManagementSelfcareUrl() {
    return KC_CONF.url.replace('/auth', '') + '/benutzerverwaltung/konto'
}

export { getKeycloakEnvConfigAndExecute, getUserManagementSelfcareUrl }
