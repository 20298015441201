<template>
    <div class="search-container" :class="{ active: searchOpen }">
        <div class="search-form">
            <form
                ref="searchform"
                role="search"
                method="GET"
                @submit.prevent="triggerSearch"
            >
                <input
                    ref="query2"
                    :placeholder="searchPlaceholder"
                    type="text"
                    data-no-crefo-ui="true"
                    name="query"
                    :data-qa="getDeviceDataQa('meta-searchInput')"
                    required
                    minlength="3"
                />
            </form>
            <a
                @click="closeSearch"
                href="javascript:void(0)"
                :data-qa="getDeviceDataQa('nav-search-close')"
                class="search-closer"
            >
                <i
                    class="crefo-ui-icon icon-close icon-color-grey"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
        <a
            @click="navSearch"
            href="javascript:void(0)"
            :data-qa="getDeviceDataQa('nav-search-opener')"
            class="search-opener"
        >
            <i
                class="crefo-ui-icon icon-magnifier icon-color-blue"
                aria-hidden="true"
            ></i>
        </a>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Search',
    data() {
        return {
            isOpen: false,
        }
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState(['navModule']),
        searchOpen: function () {
            return this.$store.state.navModule.nav.searchOpen
        },
        searchPlaceholder: function () {
            return this.$t('newsearch.searchplaceholder')
        },
    },
    methods: {
        getDeviceDataQa: function (dataQaString) {
            return this.isMobile ? `mobile-${dataQaString}` : dataQaString
        },
        backdropHandler(evt) {
            if (evt.target.classList.contains('backdrop-active')) {
                CrefoUI.Header.closeBackdropAndSubmenues() // ensure menus are closed
                this.$store.dispatch('navModule/closeAllNavMenus')
                document.body.classList.remove('backdrop-active')
                document.body.removeEventListener(
                    'click',
                    this.backdropHandler,
                    false
                )
            }
        },
        navSearch() {
            if (!this.isOpen) {
                CrefoUI.Header.closeBackdropAndSubmenues() // ensure menus are closed
                this.$store.dispatch('navModule/closeAllNavMenus')

                this.$refs.searchform.reset()
                this.$store.dispatch('navModule/openSearch')
                document.body.classList.toggle('backdrop-active')
                setTimeout(() => {
                    this.$refs.query2.focus()
                }, 500)

                // close search on backdrop click
                document.body.addEventListener(
                    'click',
                    this.backdropHandler.bind(this),
                    false
                )
                // close search on ESCAPE keypress
                document.addEventListener(
                    'keydown',
                    function backdropToggle(e) {
                        console.log('esc')
                        let isEscape = false
                        if ('key' in e) {
                            isEscape = e.key === 'Escape' || e.key === 'Esc'
                        } else {
                            isEscape = e.keyCode === 27
                        }
                        if (
                            isEscape &&
                            this.isOpen &&
                            document.body.classList.contains('backdrop-active')
                        ) {
                            this.$store.dispatch('navModule/closeSearch')
                            document.body.classList.toggle('backdrop-active')
                            document.removeEventListener(
                                'keydown',
                                backdropToggle,
                                false
                            )
                        }
                    }.bind(this),
                    false
                )
            } else {
                // submit form
                this.triggerSearch()
            }
        },
        closeSearch() {
            this.$store.dispatch('navModule/closeSearch')
            document.body.removeEventListener(
                'click',
                this.backdropHandler,
                false
            )
            if (document.body.classList.contains('backdrop-active')) {
                document.body.classList.remove('backdrop-active')
            }
        },
        triggerSearch() {
            window.singleSpaNavigate(
                '/portal/mc/erweiterte-suche/suchergebnisliste/?query=' +
                    this.$refs.query2.value
            )
            this.closeSearch()
        },
    },
    mounted() {
        this.$store.watch(
            (state) => state.navModule.nav.searchOpen,
            (newProps, oldProps) => {
                this.isOpen = newProps
            }
        )
    },
}
</script>

<style scoped lang="less">
.search-container {
    display: inline-block;
    right: 15px;
    position: absolute;
    height: 100%;
    width: 50px;
    overflow: hidden;
    transition: width 0.5s cubic-bezier(0.59, 0.03, 0.17, 1.37) 0s;
    background: #fff;

    &.active {
        width: calc(100% - 30px);
    }

    .search-form {
        display: inline-block;
        position: relative;
        width: calc(100% - 50px);

        input {
            border: 0;
            height: 50px;
            width: 100%;
            outline: none;
        }
    }
}

.search-closer {
    height: 100%;
    display: inline-block;
    position: absolute;
    width: 50px;
    right: 0;
    text-align: center;
    top: 0;

    i {
        font-size: 20px;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}

.search-opener {
    height: 100%;
    display: inline-block;
    position: absolute;
    width: 50px;
    right: 0;
    text-align: center;
    border: 1px solid transparent;

    &:hover {
        background: #ececec;
        border: 1px solid #e6e5e5;
    }

    i {
        font-size: 30px;
        top: 50%;
        right: -5px;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}
</style>
