<template>
    <nav
        class="mobile-menu"
        :class="{ active: isOpen, 'profile-menu-open': profileOpen }"
    >
        <!-- Profile Menu -->
        <ul class="profile-menu">
            <li
                class="mobile-menu--item nav-link profile-menu--opener"
                @click="toggleProfileMenu"
            >
                <span>
                    <span class="company-logo">
                        <i
                            aria-hidden="true"
                            class="crefo-brand-icon icon-building icon-color-blue"
                        />
                    </span>
                    <span class="company-name">{{ memberName }}</span>
                    <span class="open-close">
                        <i
                            aria-hidden="true"
                            class="crefo-ui-icon icon-chevron-down"
                        />
                    </span>
                </span>
            </li>
            <li class="mobile-menu--item nav-link profile-menu--slide">
                <div class="company" v-if="memberNumber">
                    <div>
                        <span
                            class="company-name text-brand-darkest-blue text-bold"
                        >
                            {{ memberName }} </span
                        ><br />
                        <span class="company-crefonumber text-brand-grey">
                            {{ $t('crefonumber.abbrev') }}.: {{ memberNumber }}
                        </span>
                        <div
                            class="edit"
                            v-if="userHasRole('CR-CP.UNTERNEHMENSDATEN')"
                        >
                            <a
                                href="/portal/mc/mein-profil/unternehmensdaten"
                                onclick="singleSpaNavigate(event)"
                                @click.prevent="closeMobileNavigation"
                            >
                                <span v-if="!isActingForOtherMemberId">
                                    {{ $t('profilemenu.companyprofile.edit') }}
                                </span>
                                <span v-else>
                                    {{ $t('profilemenu.companyprofile.view') }}
                                </span>
                            </a>
                            <a
                                class="edit"
                                href="/portal/mc/mein-profil/mitgliedsdaten"
                                onclick="singleSpaNavigate(event)"
                                @click.prevent="closeMobileNavigation"
                            >
                                <i
                                    v-if="!isActingForOtherMemberId"
                                    class="crefo-ui-icon icon-edit-pen icon-color-blue"
                                    aria-hidden="true"
                                />
                                <i
                                    v-else
                                    class="crefo-ui-icon icon-eye-open icon-color-blue"
                                    aria-hidden="true"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="user">
                    <div class="profile-picture-holder">
                        <i
                            class="profile-picture crefo-brand-icon icon-user-circle icon-color-blue"
                            aria-hidden="true"
                        />
                        <div class="user-name">
                            <span
                                class="text-brand-darkest-blue text-bold text-ellipsis"
                            >
                                {{ username }}
                            </span>
                            <span>
                                <a :href="selfCareLink" target="_blank">
                                    {{ $t('profilemenu.userprofile.edit') }}
                                </a>
                                <a
                                    class="edit"
                                    :href="selfCareLink"
                                    target="_blank"
                                >
                                    <i
                                        class="crefo-ui-icon icon-edit-pen icon-color-blue"
                                        aria-hidden="true"
                                    />
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <!-- Main Menu -->
        <ul v-if="isMitgliedsbenutzer">
            <li
                ref="menu"
                class="mobile-menu--item nav-link"
                v-bind="{ class: !item.highlight ? '' : 'highlight' }"
                v-for="(item, i) in mainMenu"
                :key="i"
            >
                <span
                    v-if="item.subitems.length"
                    :data-qa="item.dataQa"
                    @click="toggleSubmenu"
                >
                    {{ $t(item.translateKey) }}
                </span>

                <a
                    v-if="item.isRouterLink && !item.subitems.length"
                    :href="item.href"
                    onclick="singleSpaNavigate(event)"
                    @click.prevent="closeMobileNavigation"
                    :data-qa="item.dataQa"
                >
                    <span @click="closeMobileNavigation">{{
                        $t(item.translateKey)
                    }}</span>
                </a>

                <a
                    v-if="!item.isRouterLink && !item.subitems.length"
                    :data-qa="item.dataQa"
                    :href="item.href"
                >
                    {{ $t(item.translateKey) }}
                </a>
                <div class="submenu-wrapper" v-if="item.subitems.length">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <ul class="submenu">
                                    <li @click="toggleSubmenu" class="back">
                                        <span>{{ $t('mobilemenu.back') }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <ul class="submenu">
                                    <li
                                        class="mobile-submenu--item"
                                        v-for="(subitem, n) in item.subitems"
                                        :key="n"
                                        v-if="subitem.show"
                                    >
                                        <a
                                            v-if="subitem.isRouterLink"
                                            :href="subitem.href"
                                            onclick="singleSpaNavigate(event)"
                                            :data-qa="subitem.dataQa"
                                        >
                                            <span
                                                @click="closeMobileNavigation"
                                                >{{
                                                    $t(subitem.translateKey)
                                                }}</span
                                            >
                                        </a>
                                        <a
                                            v-else
                                            :href="subitem.href"
                                            :target="hrefTarget(subitem)"
                                            :data-qa="subitem.dataQa"
                                        >
                                            <span
                                                @click="closeMobileNavigation"
                                                >{{
                                                    $t(subitem.translateKey)
                                                }}</span
                                            >
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <ul v-else>
            <li
                ref="menu"
                class="mobile-menu--item nav-link"
                v-bind="{ class: !item.highlight ? '' : 'highlight' }"
                v-for="(item, i) in mainMenuRegUser"
                :key="i"
            >
                <span
                    v-if="item.subitems.length"
                    :data-qa="item.dataQa"
                    @click="toggleSubmenu"
                >
                    {{ $t(item.translateKey) }}
                </span>
                <a v-else :data-qa="item.dataQa" :href="item.href">
                    {{ $t(item.translateKey) }}
                </a>
                <div class="submenu-wrapper" v-if="item.subitems.length">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <ul class="submenu">
                                    <li @click="toggleSubmenu" class="back">
                                        <span>{{ $t('mobilemenu.back') }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12">
                                <ul class="submenu">
                                    <li
                                        class="mobile-submenu--item"
                                        v-for="(subitem, n) in item.subitems"
                                        :key="n"
                                        v-if="subitem.show"
                                    >
                                        <a
                                            v-if="subitem.isRouterLink"
                                            :href="subitem.href"
                                            onclick="singleSpaNavigate(event)"
                                            :data-qa="subitem.dataQa"
                                        >
                                            <span
                                                @click="closeMobileNavigation"
                                                >{{
                                                    $t(subitem.translateKey)
                                                }}</span
                                            >
                                        </a>
                                        <a
                                            v-else
                                            :href="subitem.href"
                                            :target="hrefTarget(subitem)"
                                            :data-qa="subitem.dataQa"
                                        >
                                            <span
                                                @click="closeMobileNavigation"
                                                >{{
                                                    $t(subitem.translateKey)
                                                }}</span
                                            >
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <!-- Service Menu -->
        <ul class="service-menu--mobile">
            <li
                class="mobile-menu--item mobile-service-menu--item nav-link bg-brand-light-grey"
                v-for="(item, i) in serviceMenu"
                v-if="item.show"
                :key="i"
            >
                <a
                    v-if="item.href !== 'logout'"
                    :href="item.href"
                    onclick="singleSpaNavigate(event)"
                    :data-qa="item.dataQa"
                >
                    <span @click="closeMobileNavigation">{{
                        $t(item.translateKey)
                    }}</span>
                </a>
                <a v-else href="#" :data-qa="item.dataQa">
                    <span @click.prevent="logout">{{
                        $t(item.translateKey)
                    }}</span>
                </a>
            </li>
        </ul>

        <!-- Language Switch -->
        <ul class="language-switcher--mobile">
            <li
                class="mobile-menu--item mobile-service-menu--item nav-link bg-brand-light-grey"
            >
                <span>
                    <span v-for="(lang, i) in languages" :key="i">
                        <span
                            v-bind="{
                                class:
                                    $i18n.locale === lang.code
                                        ? 'text-bold'
                                        : 'text-regular',
                            }"
                            @click="setLanguage(lang.code)"
                        >
                            {{ lang.name }}
                        </span>
                        <span
                            v-if="i < languages.length - 1"
                            class="text-bold devider"
                            >&nbsp;|&nbsp;</span
                        >
                    </span>
                </span>
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapState } from 'vuex'
import { dispatchEvent } from '@/helper/polyfill'
import { getUserManagementSelfcareUrl } from '@/keycloak-config'
import { clearAllStorages } from '@/helper/storage'
import {
    standardInkassoSubitems,
    getCustomInkassoLinksForClz,
} from '@/components/navigation/partials/inkasso-sub-menu-items'

export default {
    name: 'MobileMenu',
    data() {
        return {
            isOpen: false,
            profileOpen: false,
            selfCareLink: getUserManagementSelfcareUrl(),
            languages: [
                {
                    code: 'de_DE',
                    name: 'DE',
                },
                {
                    code: 'de_AT',
                    name: 'AT',
                },
                {
                    code: 'en_US',
                    name: 'EN',
                },
                {
                    code: 'fr_FR',
                    name: 'FR',
                },
                {
                    code: 'fr_LU',
                    name: 'LU',
                },
            ],
            inkassoSubitems: standardInkassoSubitems,
        }
    },
    computed: {
        ...mapState(['navModule', 'featureModule']),
        featureDrdIsActive() {
            return this.$store.state.featureModule.drd.active
        },
        username: function () {
            return `${this.$store.state.userModule.user.user.givenName} ${this.$store.state.userModule.user.user.surname}`
        },
        memberName: function () {
            if (
                this.$store.state.userModule.user.user.actForMemberName.length
            ) {
                return this.$store.state.userModule.user.user.actForMemberName
            }
            if (this.$store.state.userModule.user.user.memberName.length) {
                return this.$store.state.userModule.user.user.memberName
            }
            return this.username
        },
        memberNumber: function () {
            return (
                this.$store.state.userModule.user.user.actForMemberId ||
                this.$store.state.userModule.user.user.memberId
            )
        },
        isActingForOtherMemberId: function () {
            return (
                this.$store.state.userModule.user.user.actForMemberId !== null
            )
        },
        serviceMenu: function () {
            return [
                {
                    translateKey: 'servicemenu.about',
                    dataQa: 'navbar__item-11',
                    show: true,
                    href: '/portal/mc/ueber-creditreform',
                },
                {
                    translateKey: 'servicemenu.contact',
                    dataQa: 'navbar__item-13',
                    show:
                        this.$store.state.userModule.user.authenticated &&
                        this.$store.state.userModule.user.user
                            .isMitgliedsbenutzer,
                    href: '/portal/mc/kontakt',
                },
                {
                    translateKey: 'servicemenu.mobile.logout',
                    dataQa: 'navbar__item-logout',
                    show: this.$store.state.userModule.user.authenticated,
                    href: 'logout',
                },
            ]
        },
        mainMenu: function () {
            const getMahnwesenLink = () => {
                let subdomain = location.hostname
                    .replace('cp.', '.')
                    .replace('meine.creditreform.de', '')
                if (subdomain === '') {
                    subdomain = 'www.'
                } else if (subdomain === 'sunzinet.') {
                    subdomain = 'sedev.'
                }
                return `https://${subdomain}creditreform-mahnwesen.de/de`
            }
            return [
                {
                    translateKey: 'featurenav.inbox',
                    dataQa: 'tabCategory-inbox',
                    highlight: true,
                    isRouterLink: true,
                    href: '/portal/posteingang',
                    subitems: [],
                },
                {
                    translateKey: 'featurenav.transactions',
                    dataQa: 'tabCategory-transactions',
                    highlight: true,
                    isRouterLink: true,
                    href: '/portal/mc/vorgaenge',
                    subitems: [],
                },
                {
                    translateKey: 'featurenav.watchlist',
                    dataQa: 'tabCategory-watchlist',
                    highlight: true,
                    isRouterLink: true,
                    href: '/portal/mc/bonitaetsauskunft/watchlist-unternehmen',
                    subitems: [],
                },
                {
                    translateKey: 'mainmenu.businessreports.title',
                    dataQa: 'tabCategory-6',
                    subitems: [
                        {
                            translateKey:
                                'mainmenu.businessreports.subitem.reportb2b',
                            dataQa: 'submenuItem-27',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/mc/bonitaetsauskunft/bonitaet-einer-firma-pruefen',
                        },
                        {
                            translateKey:
                                'mainmenu.businessreports.subitem.reportb2c',
                            dataQa: 'submenuItem-201',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/mc/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen',
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.debtcollection.title',
                    dataQa: 'tabCategory-7',
                    subitems: this.inkassoSubitems,
                },
                {
                    translateKey: 'mainmenu.mycompany.title',
                    dataQa: 'tabCategory-mycompany',
                    subitems: [
                        {
                            translateKey:
                                'mainmenu.mycompany.subitem.mycompany',
                            dataQa: 'tabCategory-270',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/meine-bonitaet',
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.ubo',
                            dataQa: 'submenuItem-ubo',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/ubo/',
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.myEsg',
                            dataQa: 'submenuItem-myesg',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/myesg/',
                        },
                        {
                            translateKey:
                                'mainmenu.mycompany.subitem.companydata',
                            dataQa: 'submenuItem-companydata',
                            show: this.userHasRole('CR-CP.UNTERNEHMENSDATEN'),
                            isRouterLink: true,
                            href: '/portal/mc/mein-profil/unternehmensdaten',
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.userdata',
                            dataQa: 'submenuItem-userdata',
                            show: this.userHasRole('CR-CP.MITGLIEDSDATEN'),
                            isRouterLink: true,
                            href: '/portal/mc/mein-profil/mitgliedsdaten',
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.services.title',
                    dataQa: 'tabCategory-services',
                    subitems: [
                        {
                            translateKey: 'mainmenu.services.subitem.mahnwesen',
                            dataQa: 'submenuItem-mahnwesen',
                            show: this.isNotAustrian && this.isNotLuxemburg,
                            isRouterLink: false,
                            isExternalLink: true,
                            href: getMahnwesenLink(),
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.drd',
                            dataQa: 'submenuItem-drd',
                            show: this.featureDrdIsActive,
                            isRouterLink: true,
                            href: '/portal/drd',
                        },
                        {
                            translateKey:
                                'mainmenu.services.subitem.crefoaddress',
                            dataQa: 'submenuItem-crefoaddress',
                            show: true,
                            isRouterLink: false,
                            isExternalLink: true,
                            href: 'https://crefoaddress.de',
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.magazine',
                            dataQa: 'submenuItem-magazine',
                            show: true,
                            isRouterLink: true,
                            href: '/portal/creditreform-magazin',
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.auswertungen.title',
                    dataQa: 'tabCategory-auswertungen',
                    isRouterLink: true,
                    show: this.userHasCrMemberuserid() && !this.isActFor,
                    subitems: [],
                    href: '/portal/auskunftsexport',
                },
                {
                    translateKey: 'mainmenu.downloadbereich.title',
                    dataQa: 'tabCategory-downloadbereich',
                    show: !this.isActFor,
                    isRouterLink: true,
                    subitems: [],
                    href: '/portal/downloadbereich',
                },
            ]
        },
        mainMenuRegUser: function () {
            return [
                {
                    translateKey: 'mainmenu.mycompany.title',
                    dataQa: 'tabCategory-mycompany',
                    subitems: [
                        {
                            translateKey:
                                'mainmenu.mycompany.subitem.mycompany',
                            dataQa: 'tabCategory-meine-bonitaet',
                            show: true,
                            highlight: true,
                            isRouterLink: true,
                            href: '/portal/meine-bonitaet',
                            subitems: [],
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.ubo',
                            dataQa: 'tabCategory-ubo',
                            show: true,
                            highlight: true,
                            isRouterLink: true,
                            href: '/portal/ubo/',
                            subitems: [],
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.myEsg',
                            dataQa: 'tabCategory-myesg',
                            show: true,
                            highlight: true,
                            isRouterLink: true,
                            href: '/portal/myesg/',
                            subitems: [],
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.services.title',
                    dataQa: 'tabCategory-services',
                    subitems: [
                        {
                            translateKey: 'mainmenu.services.subitem.drd',
                            dataQa: 'submenuItem-drd',
                            show: this.featureDrdIsActive,
                            isRouterLink: true,
                            href: '/portal/drd',
                        },
                    ],
                },
                {
                    translateKey: 'mainmenu.downloadbereich.title',
                    dataQa: 'tabCategory-downloadbereich',
                    show: !this.isActFor,
                    isRouterLink: true,
                    subitems: [],
                    href: '/portal/downloadbereich',
                },
            ]
        },
        isNotAustrian: function () {
            return this.$i18n.locale !== 'de_AT'
        },
        isNotLuxemburg() {
            return this.$i18n.locale !== 'fr_LU'
        },
        isMitgliedsbenutzer: function () {
            return this.$store.state.userModule.user.user.isMitgliedsbenutzer
        },
        isActFor() {
            return this.$store.state.userModule.user.user.actForMemberId
        },
    },
    methods: {
        closeMobileNavigation() {
            this.$store.dispatch('navModule/closeMobile')
            if (document.body.classList.contains('backdrop-active')) {
                document.body.classList.remove('backdrop-active')
            }
            if (document.body.classList.contains('mobile-menu-opened')) {
                document.body.classList.remove('mobile-menu-opened')
            }
        },
        toggleSubmenu(evt) {
            this.profileOpen = false
            document.querySelector('.mobile-menu').scrollTo(0, 0)
            let $el = evt.target
            while (!$el.classList.contains('mobile-menu--item')) {
                $el = $el.parentNode
            }
            $el.classList.toggle('submenu-opened')
        },
        toggleProfileMenu() {
            this.profileOpen = !this.profileOpen
        },
        setLanguage(langCode) {
            document.cookie = `CAS_PREFERRED_LANGUAGE=${langCode}; SameSite=None; Secure;domain=creditreform.de;path=/`
            this.$i18n.locale = langCode
            if (document.body.classList.contains('backdrop-active')) {
                document.body.classList.remove('backdrop-active')
            }
            this.$store.dispatch('userModule/setLanguage', langCode)
            window.setTimeout(() => {
                dispatchEvent('languageChanged')
            }, 250)
        },
        userHasCrMemberuserid() {
            return !!this.$store.state.userModule.user.user.crMemberuserid
        },
        userHasRole(roleName) {
            return this.$store.state.userModule.user.user.user_roles.includes(
                roleName
            )
        },
        logout() {
            clearAllStorages()
            dispatchEvent('loggedOut')

            const keycloakExists = setInterval(() => {
                if (
                    window.keycloak &&
                    window.keycloak.logout &&
                    typeof window.keycloak.logout === 'function'
                ) {
                    clearInterval(keycloakExists)
                    window.keycloak.logout({
                        redirectUri: this.$router.getLogoutUrl(),
                    })
                    window.setTimeout(() => {
                        window.keycloakStatusChecked = true
                    }, 1000)
                }
            }, 200)
        },
        hrefTarget(el) {
            return el.isExternalLink ? '_blank' : ''
        },
    },
    watch: {
        isOpen(val) {
            // We want to reset the menu state, whenever the menu gets closed
            if (!val) {
                this.profileOpen = false
                document
                    .querySelectorAll('.mobile-menu--item')
                    .forEach((item) => {
                        item.classList.remove('submenu-opened')
                    })
            }
        },
    },
    mounted() {
        const userClz = this.$store.state.userModule.user.user.clz
        if (userClz !== null) {
            getCustomInkassoLinksForClz(userClz).then((subitems) => {
                this.inkassoSubitems = subitems
            })
        } else {
            this.$store.watch(
                (state) => state.userModule.user.user.clz,
                (newClz, _oldClz) => {
                    if (newClz) {
                        getCustomInkassoLinksForClz(newClz).then((subitems) => {
                            this.inkassoSubitems = subitems
                        })
                    }
                }
            )
        }

        this.$store.watch(
            (state) => state.navModule.nav.mobileOpen,
            (newProps) => {
                this.isOpen = newProps
            }
        )

        this.$store.watch(
            (state) => state.userModule.user.clientLang,
            (newProps, oldProps) => {
                if (newProps && newProps !== oldProps) {
                    this.setLanguage(newProps)
                }
            }
        )
    },
}
</script>

<style scoped lang="less">
.mobile-menu {
    z-index: 1001;
    transition: all 0.5s ease-in-out;
    background: #fff;
    height: 100vh;
    top: 50px;
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    right: -100%;
    border-top: 1px solid #e6e5e5;

    &.active {
        right: 0;
    }

    .text-regular {
        font-weight: normal !important;
    }

    .language-switcher--mobile {
        .devider {
            margin: 0 8px;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;
        height: auto;
        position: relative;

        li {
            background: #ffffff;
            flex: 1;
            display: flex;
            align-items: center;
            padding: 0;
            border-bottom: 1px solid #e6e5e5;
            height: 50px;

            &.highlight {
                background: #009ee3;
                &.mobile-menu--item {
                    & > span,
                    & > a {
                        color: #fff;
                    }
                }
            }

            &.mobile-service-menu--item {
                border-bottom: 1px solid #fff;
            }

            .back {
                color: #4c4c4c;
                text-transform: uppercase;
                border-bottom: 1px solid #e6e5e5;

                span {
                    padding-left: 30px;
                }

                &:before {
                    font-family: 'crefo-ui-icons' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    background-image: none !important;
                    text-shadow: none !important;
                    font-size: 23px;
                    content: '\e904';
                    position: absolute;
                    left: 15px;
                }
            }

            &.mobile-menu--item:not(.mobile-service-menu--item):not(
                    .profile-menu--opener
                ) {
                & > span,
                & > a {
                    &:after {
                        font-family: 'crefo-ui-icons' !important;
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        background-image: none !important;
                        text-shadow: none !important;
                        font-size: 23px;
                        content: '\e903';
                        position: absolute;
                        right: 15px;
                    }
                }
            }

            &.mobile-menu--item {
                height: 50px;

                &.mobile-service-menu--item {
                    & > span,
                    & > a {
                        color: #4c4c4c;
                    }
                }

                & > span,
                & > a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    padding: 0 15px;
                    position: relative;
                    color: #009ee3;
                    font-weight: bold;
                }

                &.submenu-opened {
                    .submenu-wrapper {
                        right: 0;
                    }
                }

                .submenu-wrapper {
                    width: 100%;
                    position: absolute;
                    height: calc(100% + 250px);
                    top: 0;
                    background: #fff;
                    z-index: 1;
                    right: -2000px;
                    transition: all 0.4s;

                    > .container {
                        padding: 0;
                    }

                    ul {
                        padding-top: 0;

                        li {
                            height: 50px;
                            font-weight: bold;
                            padding: 0 15px;

                            a {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-menu {
    &.profile-menu-open {
        .profile-menu {
            &--opener {
                .open-close {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }

            &--slide {
                height: 210px !important;
                align-items: center !important;

                > div {
                    align-items: center !important;
                }
            }
        }
    }

    .profile-menu {
        &--opener {
            height: 100px !important;

            i {
                vertical-align: middle;
            }

            .company-logo {
                border: 1px solid #edece5;
                padding: 12px 8px 16px;

                i {
                    font-size: 32px;
                }
            }

            .company-name {
                margin: 0 15px;
            }

            .open-close {
                i {
                    display: inline-block;
                    transition: transform 0.3s ease-in-out;
                    position: absolute;
                    right: 15px;
                    top: 39px;
                }
            }
        }

        &--slide {
            height: 0 !important;
            transition: all 0.5s;
            align-items: baseline !important;
            padding: 0 15px;
            flex-direction: column;

            > div {
                align-items: end !important;
                transition: all 0.5s;
            }

            .company {
                text-align: left;
                width: 100%;
                padding-bottom: 15px;
                border-bottom: 1px solid #edece5;
                margin-bottom: 15px;
                margin-top: 20px;

                & > div {
                    padding-left: 67px;
                }

                &-name {
                    font-size: 16px;
                    margin-bottom: 5px;
                    display: inline-block;
                }

                .edit {
                    position: relative;
                    padding-top: 15px;
                    margin-bottom: 10px;
                }
                a.edit {
                    position: absolute;
                    right: 0;
                    top: -2px;
                }
            }

            & > .user:first-child {
                margin-top: 15px;
            }

            .user {
                text-align: left;
                padding-bottom: 15px;
                margin-bottom: 15px;
                width: 100%;

                .profile-picture-holder {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: flex-start;
                }

                .profile-picture {
                    border: 1px solid #edece5;
                    border-radius: 50%;
                    padding: 10px;
                    font-size: 32px;
                    display: inline-block;
                    margin-right: 15px;
                }

                &-name {
                    font-size: 14px;
                    display: inline-block;
                    width: calc(100% - 69px);

                    & > span:first-of-type {
                        margin-bottom: 5px;
                    }

                    & > span {
                        position: relative;
                        display: inline-block;
                        width: 100%;
                    }

                    a {
                        display: inline-block;
                        width: 100%;
                        padding-right: 35px;
                    }

                    a.edit {
                        display: inline;
                        padding-right: 0;
                        width: auto;
                        position: absolute;
                        right: 0;
                        top: -5px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .mobile-menu {
        display: none;
    }
}
</style>
