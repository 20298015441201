<template>
    <a href="javascript:void(0)" class="mobile-main-menu-opener mobile-login">
        <span>{{ $t('mainmenu.login') }}</span>
        <i
            class="crefo-ui-icon icon-enter icon-color-blue"
            aria-hidden="true"
        />
    </a>
</template>

<script>
export default {
    name: 'MobileLoginButton',
}
</script>

<style scoped lang="less">
.mobile-login {
    span {
        margin-right: 15px;
        padding-top: 2px;
        display: inline-block;
        vertical-align: top;
    }
}
</style>
