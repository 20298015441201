<template>
    <ul class="service-menu">
        <li
            class="menu-item"
            v-for="(item, index) in serviceMenu"
            :key="index"
            v-if="item.show"
        >
            <a
                :href="item.href"
                :data-qa="item.dataQa"
                onclick="singleSpaNavigate(event)"
            >
                {{ $t(item.translateKey) }}
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'ServiceMenu',
    computed: {
        serviceMenu: function () {
            return [
                {
                    translateKey: 'servicemenu.about',
                    dataQa: 'navbar__item-11',
                    show: true,
                    href: '/portal/mc/ueber-creditreform',
                },
                {
                    translateKey: 'servicemenu.contact',
                    dataQa: 'navbar__item-13',
                    show:
                        this.$store.state.userModule.user.authenticated &&
                        this.$store.state.userModule.user.user
                            .isMitgliedsbenutzer,
                    href: '/portal/mc/kontakt',
                },
            ]
        },
    },
}
</script>

<style scoped lang="less">
.flex-header {
    .service-menu {
        top: 0;
        transform: none;
        position: relative;
    }
}
@media only screen and (min-width: 768px) {
    .header-nav.has-mainmenu ul.service-menu,
    .header-nav.has-servicemenu ul.service-menu {
        right: 15px;
    }
}
</style>
