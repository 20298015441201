<template>
    <div
        v-if="isAuthenticated && actForMemberName"
        data-qa="membernavigation--footer-banner"
        class="sticky-footer footer-warning"
    >
        <div class="footer-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-8 sticky-footer--msg">
                        <p>
                            {{ $t('actfor.footerbanner.text') }}
                            <span class="text-bold"
                                ><span
                                    data-qa="membernavigation--footer-banner--actForMemberName"
                                    >{{ actForMemberName }}</span
                                >
                                (<span
                                    data-qa="membernavigation--footer-banner--actForMemberId"
                                    >{{ actForMemberId }}</span
                                >)</span
                            >.
                        </p>
                    </div>
                    <div class="col-4 sticky-footer--btn">
                        <button
                            data-qa="membernavigation--footer-banner--btn"
                            class="btn btn-default btn-min btn-small"
                            @click="resetOriginalMember"
                        >
                            {{ $t('actfor.footerbanner.leave') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'CrefoActForMemberFooter',
    computed: {
        ...mapState(['userModule']),
        isAuthenticated: function () {
            return this.$store.state.userModule.user.authenticated
        },
        actForMemberName: function () {
            return !this.$store.state.userModule.user.user.actForMemberName
                .length
                ? false
                : this.$store.state.userModule.user.user.actForMemberName
        },
        actForMemberId: function () {
            return this.$store.state.userModule.user.user.actForMemberId ===
                null
                ? 0
                : this.$store.state.userModule.user.user.actForMemberId
        },
    },
    methods: {
        resetOriginalMember() {
            sessionStorage.removeItem('actForMemberId')
            sessionStorage.removeItem('actForMemberName')
            sessionStorage.removeItem('originalMemberId')
            sessionStorage.removeItem('reportAct4SbNumber')

            this.$store.dispatch('userModule/setActForMember', {
                actForMemberId: null,
                actForMemberName: '',
                reportAct4SbNumber: null,
            })

            // Redirect to homepage on member change
            window.setTimeout(() => {
                location.href = '/'
            }, 500)
        },
    },
}
</script>

<style scoped>
.sticky-footer {
    background: #fff;
    -webkit-box-shadow: 0 0 0.875rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 0.875rem rgba(0, 0, 0, 0.15);
    z-index: 201;
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}

.sticky-footer.footer-info {
    background: #a763a4;
}

.sticky-footer.footer-success {
    background: #8acac1;
}

.sticky-footer.footer-warning {
    background: #dddf4a;
}

.sticky-footer.footer-error {
    background: #e05469;
}

.sticky-footer .footer-wrapper {
    min-height: 20px;
    z-index: 2;
    position: relative;
}

.sticky-footer .footer-wrapper .row,
.sticky-footer .footer-wrapper .row > .col-12,
.sticky-footer .footer-wrapper .row > .col-4,
.sticky-footer .footer-wrapper .row > .col-8 {
    min-height: 20px;
}

.sticky-footer .sticky-footer--msg p {
    padding-top: 15px;
    font-size: 15px;
    line-height: 24px;
}

.sticky-footer .sticky-footer--btn {
    text-align: right;
    padding-top: 12px;
}

.sticky-footer .sticky-footer--btn .btn {
    margin-bottom: 10px;
}
</style>
