<template>
    <div>
        <SuperUserMessage />
        <CrefoDefaultNavigation />
        <CrefoSidebarNavigation />
        <CrefoActForMemberFooter />
        <ScrollTopButton />
    </div>
</template>

<script>
import SuperUserMessage from './components/navigation/partials/SuperUserMessage'
import CrefoDefaultNavigation from './components/navigation/CrefoDefaultNavigation'
import CrefoSidebarNavigation from './components/navigation/CrefoSidebarNavigation'
import CrefoActForMemberFooter from './components/navigation/CrefoActForMemberFooter'
import ScrollTopButton from './components/ScrollTopButton'
export default {
    components: {
        SuperUserMessage,
        CrefoDefaultNavigation,
        CrefoSidebarNavigation,
        CrefoActForMemberFooter,
        ScrollTopButton,
    },
    created() {
        if (window.keycloakStatusChecked === undefined) {
            window.keycloakStatusChecked = false
        }

        window.addEventListener(
            'unreadMessagesCountChanged',
            () => {
                this.$store.commit(
                    'navModule/SET_UNREAD_MESSAGES_COUNT',
                    window.unreadMessages
                )
            },
            false
        )

        window.addEventListener(
            'watchlistCountChanged',
            () => {
                this.$store.dispatch('navModule/setWatchlistCount')
            },
            false
        )

        if (process && process.env) {
            console.log(
                `SCS ${process.env.PACKAGE_NAME} ${process.env.PACKAGE_VERSION}`
            )
            console.log('process.env.VARS: ', process.env.VARS)
        }
    },
    mounted() {
        let self = this
        // callback executed when data-ready=true
        function addWebtrekkClickListeners() {
            console.log('data-ready')

            let actionLinks = document.querySelectorAll('[data-wt-action-name]')
            if (actionLinks && actionLinks.length) {
                let i
                console.dir(actionLinks)
                for (i = 0; i < actionLinks.length; i++) {
                    actionLinks[i].addEventListener(
                        'click',
                        (e) => {
                            e.preventDefault()
                            let action = {
                                name: e.target.getAttribute(
                                    'data-wt-action-name'
                                ),
                            }
                            if (
                                e.target.hasAttribute('data-wt-action-params')
                            ) {
                                let params = false
                                try {
                                    params = JSON.parse(
                                        e.target.getAttribute(
                                            'data-wt-action-params'
                                        )
                                    )
                                } catch (e) {}
                                if (params) {
                                    action.parameter = params
                                }
                            }
                            if (e.target.hasAttribute('data-wt-action-goal')) {
                                let goals = false
                                try {
                                    goals = JSON.parse(
                                        e.target.getAttribute(
                                            'data-wt-action-goal'
                                        )
                                    )
                                } catch (e) {}
                                if (goals) {
                                    action.goal = goals
                                }
                            }
                            console.log('webtrekk action tracked: ', action)
                            self.$webtrekk.action(action)
                            self.$webtrekk.track()
                        },
                        false
                    )
                }
            }
        }

        window.addEventListener(
            'dataReady',
            () => {
                console.log('caught dataReady event')
                addWebtrekkClickListeners()
            },
            false
        )
    },
}
</script>

<style>
/***************************
 *
 * Thank you, design team!
 *
 **************************/
@media only screen and (max-width: 767px) {
    html {
        overflow-x: hidden;
    }
    .mc-mf--content-wrapper {
        padding-left: 0 !important;
        margin-top: 50px !important;
    }
}
@media only screen and (min-width: 768px) {
    .mc-mf--content-wrapper {
        padding-left: 105px !important;
    }
    body.prevent-scroll {
        overflow: hidden !important;
    }
}
@media only screen and (min-width: 1200px) {
    .mc-mf--content-wrapper {
        padding-left: 100px !important;
    }
}
@media only screen and (min-width: 1330px) {
    .mc-mf--content-wrapper {
        padding-left: 0 !important;
    }
}
</style>
