<template>
    <div
        class="scroll-top-badge"
        data-qa="scroll-top-badge"
        :class="{ 'is-visible': show }"
        @click="scrollToTop"
    >
        <div class="button">
            <i class="crefo-ui-icon icon-chevron-up" aria-hidden="true"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScrollTopButton',
    data() {
        return {
            show: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 200) {
                this.show = true
            } else {
                this.show = false
            }
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        },
    },
}
</script>

<style scoped lang="less">
.scroll-top-badge {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 50%;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
    cursor: pointer;
    z-index: 10;

    .button {
        position: absolute;
        right: 20px;
        bottom: 20px;
        border-radius: 50%;
        width: 60px;
        line-height: 60px;
        text-align: center;
        -webkit-box-shadow: 3px 2px 7px rgba(0, 0, 0, 0.5);
        box-shadow: 3px 2px 7px rgba(0, 0, 0, 0.5);
        background-color: #008dd9;
    }
}

@keyframes lightSpeedIn {
    from {
        transform: translate3d(100%, 0, 0) skewX(-30deg);
        opacity: 0;
    }

    60% {
        transform: skewX(20deg);
        opacity: 1;
    }

    80% {
        transform: skewX(-5deg);
        opacity: 1;
    }

    to {
        transform: none;
        opacity: 1;
    }
}

.is-visible {
    animation-name: lightSpeedIn;
    animation-timing-function: ease-out;
}
</style>
